<template>
    <div class="app-upload">
        <BaseInput rounded outlined label="Ставка" validation="price" @sendData="$emit('sendData', $event)" />
        <div class="comment">Чем выше ставка, тем выше позиция вашей анкеты в поисковой выдаче</div>
        <div class="subcomment">Ставка списывается каждый час с вашего баланса</div>
        <AppRegularButton text="установить" dark :loading="loading" :disabled="disabled" @action="$emit('action')" />
        <BaseCloseIcon class="icon" @click="$emit('close')"/>        
    </div>
</template>

<script>
import AppRegularButton from "@/components/buttons/app-regular-btn.vue";
    export default {
        components: {
            AppRegularButton
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-upload{
        width: 320px;
        height: 300px;
        background-color: $primary;
        border-radius: 0.4em;
        border: 1px solid $accent-dark;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .comment{
            padding: 0 1em;
            text-align: center;
        }
        .subcomment{
            padding: 0 1em;
            text-align: center;
            font-size: 0.8em;
            font-weight: 700;
        }
        .icon{
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }

    }
</style>