<template>
    <transition name="popup">
            <AppPopupSetBet 
                v-if="popup.isOpened"
                @close="popup.close"
                @sendData="massagistFinance.getNewBet($event)"
                :disabled="isBetValid"
                @action="massagistFinance.setMassagistBet"  />
    </transition>
    <div class="app-massagist-personal-page-finance">
        <Payment v-if="payment.isOpened" @closePayment="payment.close($event)" />
        <AppPageLoadingAnimation v-if="massagistFinance.loading" />
        <div class="main-info">
            <div class="your-bet block">
                <div class="screen">
                    <h3 class="block-title">Ваша ставка</h3>
                    <span class="block-main-text">{{ massagistFinance.bet }} руб/час</span>
                </div>
                <AppRegularBtn text="Поднять" light @action="popup.open" />
            </div>
            <div class="your-balance block">
                <div class="screen">
                    <h3 class="block-title">Баланс</h3>
                    <span class="block-main-text">{{ +massagistFinance.balance + +massagistFinance.bonus }} руб</span>
                    <span class="bonus">в том числе бонус {{ +massagistFinance.bonus }} руб</span>
                </div>
                <AppRegularBtn text="пополнить" light @action="payment.open" />
            </div>
            <div class="max-bet block">
                <div class="screen">
                    <span class="secondary-text">Максимальная ставка в вашем регионе</span>
                    <h3 class="block-title">{{ +massagistFinance.maxBetForCity }} руб/час</h3>
                </div>
            </div>
        </div>
        <div class="sub-info">
            <h2>Ваша ставка находится на <span>{{ massagistFinance.massagistPosition }} позиции</span>  по региону</h2>
            <AppRegularBtn text="поднять" light @action="popup.open"/>
        </div>
    </div>
</template>

<script>
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
import AppPopupSetBet from "@/components/forms/app-set-bet-form.vue";
import AppPageLoadingAnimation from "@/components/animations/app-page-loading-animation.vue";
import Payment from "@/payment/payment.vue";
import massagistApi from "@/api/massagist.js";
import { reactive, onMounted, computed } from 'vue';
import { useStore } from "vuex";
    export default {
        components: {
            AppRegularBtn,
            AppPopupSetBet,
            AppPageLoadingAnimation,
            Payment
        },
        setup(){
            const store = useStore();
            const popup = reactive({
                isOpened: false,
                open: () => popup.isOpened = true,
                close: () => {
                    popup.isOpened = false;
                    massagistFinance.newBet = 0;
                }
            })
            const payment = reactive({
                isOpened: false,
                open: () => payment.isOpened = true,
                close: () => payment.isOpened = false
            })
            const massagistFinance = reactive({
                loading: true,
                massagistID: store.state.user.user.id,
                token: store.state.user.token,
                city: store.state.user.user.city,
                bet: 0,
                newBet: 0,
                balance: 0,
                bonus: 0,
                maxBetForCity: 0,
                massagistPosition: null,
                getMassagistFinance: async (token, id) => {
                    await massagistApi.getMassagistBet({ token: token, massagist_id: id })
                        .then(response => massagistFinance.bet = response.data.bet)
                        .catch(err => console.log(err));
                    await massagistApi.getMassagistBalance({ token: token, massagist_id: id })
                        .then(response => {
                            massagistFinance.balance = response.data.balance;
                            massagistFinance.bonus = response.data.bonus;
                            store.dispatch('setMassagistBalance', {
                                balance: massagistFinance.balance,
                                bonus: massagistFinance.bonus
                            })

                        })
                        .catch(err => console.log(err));
                    
                    massagistFinance.loading = false;
                },
                getNewBet: (data) => massagistFinance.newBet = data,
                setMassagistBet: async () => {
                    massagistFinance.loading = true;
                    await massagistApi.setMassagistBet({ token: massagistFinance.token, massagist_id: massagistFinance.massagistID, bet: massagistFinance.newBet })
                        .catch(err => console.log(err));
                    await massagistFinance.getMassagistFinance(massagistFinance.token, massagistFinance.massagistID);
                    await massagistFinance.getMaxBetForCity({ city: massagistFinance.city })
                    popup.close();
                },
                getMaxBetForCity: async () => {
                    await massagistApi.getMaxBetForCity({ city: massagistFinance.city })
                        .then(response => {
                            massagistFinance.maxBetForCity = response.data.maxBet.bet;
                            response.data.sortedBets.forEach((bet, index) => {
                                if(bet.massagist_id === massagistFinance.massagistID){
                                    massagistFinance.massagistPosition = index + 1;
                                }
                            })
                            console.log(response.data)
                            
                        })
                        .catch(err => console.log(err))
                }
            })

            const isBetValid = computed(() => {
                if(massagistFinance.newBet > 0 && massagistFinance.newBet < +massagistFinance.balance + +massagistFinance.bonus){
                    return false
                } else {
                    return true
                }
            })

            onMounted(massagistFinance.getMassagistFinance(massagistFinance.token, massagistFinance.massagistID), massagistFinance.getMaxBetForCity())

            return {
                massagistFinance,
                popup,
                isBetValid,
                payment
            }
        }
    }
</script>

<style lang="scss" scoped>
 .popup-enter-active{
        transition: 0.2s ease;
    }
    .popup-enter-from {
        transform: translate(-50%, -50%) scale(0);
    }
    .popup-enter-to{
        transform: translate(-50%, -50%) scale(1);
    }
    .scpopupale-leave-active{
        transition: 0.2s ease;
    }
    .popup-leave-from {
        transform: translate(-50%, -50%) scale(1);
    }
    .popup-leave-to{
        transform: translate(-50%, -50%) scale(0);
    }
    .app-massagist-personal-page-finance{
        min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .main-info{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            padding: 2em;
            width: 100%;
            .block{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 250px;
                margin: 1em;
                .screen{
                    background-color: $primary-dark;
                    padding: 1em;
                    border-radius: 0.5em;
                    text-align: center;
                    margin-bottom: 2em;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 150px;
                    .block-title{
                        font-size: 2em;
                        color: $white;
                    }
                    .block-main-text{
                        font-size: 2em;
                        color: $white;
                    }
                    .secondary-text{
                        color: $white;
                        font-weight: 700;
                    }
                    .bonus{
                        font-weight: 700;
                    }
                }
            }
        }
        .sub-info{
            text-align: center;
            h2{
                font-size: 2em;
                margin-bottom: 1em;
                span{
                    color: $accent-dark;
                }
            }
        }
    }
</style>